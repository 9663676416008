/*!
 * Evil Corp (https://evilcorp.com.ar/)
 * Copyright 2004-2020 EvilCorp S.A.
 */

h1,h2,h3,h4,h5{
	font-family: objektiv-mk1, $font-family-sans-serif;
}


.icon-sm{
	height: 24px;
	width: auto;
}

.icon-md{
	height: 32px;
	width: auto;
}

.icon-lg{
	height: 48px;
	width: auto;
}

.icon-xl{
	height: 80px;
	width: auto;
}


.nav.ev-tabs{
	.nav-item {
		margin-right: 1rem;
		&:last-child{
			margin-right: 0;
		}
		.nav-link{
			color: $gray-600;
			border-bottom: 3px solid transparent;
		}
		.nav-link.active{
			border-bottom-color: $primary;
		}
	}
}

.metrics{
	.metric-value{
		font-weight: 900;
	}
	.metric{
		@extend .mb-4;
	}
}

.metric-trend{
	font-size: .7rem;
}

.donnut{
	@extend .mr-3;
    display: flex;
	position: relative;
    align-items: center;
    justify-content: center;
	min-width: 120px;
	height: 120px;

	svg{
		position: absolute;
		z-index: 1;
	}
	h6{
		position: absolute;
		text-align: center;
		z-index: 2;
	}
}


.dot{
	display: inline-block;
	height: 12px;
	width: 12px;
	border-radius: 50%!important;
	margin-right: 3px;
}


.btn.btn-subtle {
	background-color: transparent;
	border-color: transparent;
	&:hover{
		background-color: #e2e6ea;
	}
}

header{
	z-index: 40;
	.navbar-brand img{
		@extend .mr-md-5;
		width: 120px;

	}
	.navbar.navbar-light{
		padding-top: 0;
		padding-bottom: 0;
		background-color: #FFF !important;
		border-bottom: 1px solid #EEE;
	}
	.navbar-nav{
		.nav-item>.nav-link{
			padding-top: 22px;
			padding-bottom: 16px;
			border-bottom: 2px solid transparent;
			margin-right: 10px;
			font-size: .75rem;
			line-height: 1.2rem;
			font-weight: 500;
			text-transform: uppercase;
		}
		.nav-item.active>.nav-link{
			border-bottom-color: $primary;
		}
		.icon{
			display: inline-block;
			font-size: 1rem;
			margin-right: 4px;
			margin-top: -2px;
		}
	}
}
main{
	z-index: 39;
	@extend .mt-4;
	padding-bottom: 3rem;
}

body.Dashboards.display{
	main{
		margin-top: 0 !important;
	}
}

.breadcrumb{
	background-color: #FFF;
	padding: .35rem 1rem;
	font-size: $font-size-sm;
	& > li + li:before {
	    padding: 0 5px;
	    color: #ccc;
	    content: "/ ";
	}
}

/* Grid */

table.table{
	th{
		font-size: $font-size-sm;
		a{
			color: $gray-600;
		}
	}
	td.actions:last-child{
		text-align: right;
		.btn-more{
			margin-top: -8px;
			margin-bottom: -6px;
			padding: 0 1rem;
			font-size: 1.35rem;
		}
	}
}


.input-group-search{
	max-width: 320px;

	.input-group-append{
		.btn.btn-search-reset,
		.btn.btn-search-submit{
			border-color: #ced4da;
		}
		.btn.btn-search-reset{
			border-left-color: transparent;
			background-color: $white;
			&:hover{
				background-color:#e2e6ea;
			}
		}
	}
	.form-input-search{
		border-right-color: transparent;
	}
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}
