/*!
 * Evil Corp (https://evilcorp.com.ar/)
 * Copyright 2004-2020 EvilCorp S.A.
 */


.doc-container .doc-content{

	max-width: 60rem;

	h1{
		margin-top: 2rem;
		margin-bottom: 3rem;
	}

	h2,h3,h4{
		margin-top: 3rem;
		margin-bottom: 1rem;
	}
	h5,h6{
		margin-top: 2rem;
		margin-bottom: 1rem;
	}

}